.components_quote-card-container {
	width: 100vw;
	max-width: 420px;
	margin: 0;
	padding: 0.8em 1.5em;
	padding-bottom: 0.5em;
	border-radius: 5px;
	border: 1px solid $ExtraLight;
	overflow: hidden;
	box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);

	.photo {
		display: block;
		width: 100%;
		padding-left: 0.5em;
		// justify-content: center;

		img{
			box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
			margin-bottom: 1em;
		}
	}

	li {
		display: block;
		width: 100%;
		// border: 2px solid red;

		p {
			font-size: 0.9em;
			line-height: 1.2em;
			

			&.user-id {
				font-size: 0.9em;
			}

			span {
				font-size: 1em;
			}

			&#author {
				font-size: 0.6em;
				display: flex;
				width: auto;
				justify-content: flex-end;
				margin-right: 0.5em;
				margin-bottom: 2em;
				margin-top: 0.8em;
				
				b{
					position: relative;
				}

				b::before{
					content: '';
					width: 1.5em;
					height: 0.2em;
					background-color: $mainColor;
					position: absolute;
					top: 50%;
					left: -1.8em;
				}
			}
		}
	}
	.icons-container {
		display: flex;
		margin-top: 0.6em;

		.likes {
			display: flex;
			flex-direction: row;

			p {
				@include font-size(18px, 22px);
				color: $MedLight;
				height: 1.1em;
				line-height: 1.1em;
			}
		}
	}
}

// Desktop Size
@media screen and (min-width: 1200px) {
	.components_quote-card-container {
		max-width: 550px;
		height: 100%;
	}
}
