.components_share-social {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0.5em auto;
	padding: 3.5em 4em 2em;
	border-radius: 0.3em;
	background-color: $Light;

	&__element {
		display: flex;
		flex-direction: row;
		margin: 0.5em auto;
	}

	p {
		text-align: center;
		margin-top: 0.8em;
		color: green;
	}
}

.social-share > * {
	margin-bottom: 1em;
}
