#modal {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100vw;
	max-width: $maxWidth;
	height: 100vh;
	padding: 0;
	background-color: rgba($mainColor, 0.2);
	overflow: hidden;
	z-index: 10;
}
