.image-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 1em;

	.image-avatar {
		width: 5em;
		height: 5em;
		border-radius: 50%;
		margin: auto;
	}
}

img.elements_image-avatar_header {
	width: 3.5vh;
	height: 3.5vh;
	object-fit: cover;
	border-radius: 50%;
	margin-top: 0.2em;
	margin-bottom: 0.3em;
}

img.elements_image-avatar {
	width: 11vh;
	height: 11vh;
	object-fit: cover;
	border-radius: 50%;
	margin-bottom: 0.3em;
}

// Desktop Size
@media only screen and (min-width: $maxWidth) {
	img.elements_image-avatar_header {
		width: 4vh;
		height: 4vh;
	}
}
