.components_main {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	overflow-y: auto;
	scroll-behavior: smooth;
	// border: 2px solid red;

	.initial-container {
		display: block;

		.initial-quotes {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
		}
	}

	.list-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 0;
		margin-top: 1em;
		// border: 2px solid red;

		h1 {
			@include font-size(22px, 28px);
			font-weight: 500;
			font-style: italic;
			color: $mainColor;
			padding: 0 1em;
			margin-bottom: 1em;
			text-align: center;
		}
		h2 {
			@include font-size(20px, 26px);
			padding: 0 1em;
			font-weight: 300;
			margin-bottom: 0.5em;
			text-align: center;
		}

		.Loading {
			display: block;
			@include font-size(28px, 30px);
			font-weight: 500;
			line-height: 1.1em;
			margin: 3em auto;
			color: $mainColor;
		}
	}
}

// Desktop Size
@media only screen and (min-width: $maxWidth) {
	.components_main {
		.list-container {
			gap: 1em;
		}
	}

	.list-container {
		h1,
		h2 {
			margin-top: 2rem;
		}
	}
}
