body {
	.home-container {
		position: relative;
		width: 100vw;
		max-width: $maxWidth;
		height: 100vh;
		margin: 0;
		padding: 0;
		margin: auto;
		overflow: hidden;
		border: 1px solid $Light;

		header {
			@include font-size(16px, 24px);
			display: block;
			width: 100%;
			height: 10vh;
			color: $Light;
			background-color: $mainColor;
			z-index: 10;
			// border: 1px solid $Light;
		}
		main {
			@include font-size(20px, 28px);
			display: block;
			width: 100%;
			height: 85vh;
			color: $Dark;
			background-color: $Light;
			overflow: hidden;
			// border: 1px solid red;
		}
	}
}

// Desktop Size
@media only screen and (min-width: $maxWidth) {
	body {
		.home-container {
			header {
				height: 12vh;
			}
			main {
				height: 82vh;
			}
		}
	}
}
