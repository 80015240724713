.component_header-line {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 4.5vh;
	padding-top: 0.3em;
	padding-left: 0.3em;
	padding-right: 0.3em;

	h3 {
		height: 4vh;

		#logo-init {
			position: absolute;
			left: 2rem;
			top: 1.2vh;
			@include font-size(30px, 48px);
			font-family: 'Parisienne', cursive;
			font-weight: 300;
			color: $Light;
			text-decoration: none;
		}

		#logo {
			@include font-size(20px, 38px);
			font-family: 'Parisienne', cursive;
			font-weight: 300;
			color: $Light;
			text-decoration: none;
			transition: all 0.5s ease;

			&:hover {
				color: $mainColor;
				border: 2px solid $mainColor;
				background-color: $Light;
				padding-left: 0.5em;
				padding-right: 0.5em;
				border-radius: 0.5em;
			}
		}
	}

	.loading {
		position: absolute;
		right: 2rem;
		top: 50%;
		@include font-size(18px, 28px);
		margin-right: 1em;
	}

	.line-items {
		display: flex;
		height: 5vh;

		button {
			// @include font-size(16px, 22px);
			font-size: 0.9em;
			padding: 0;
			padding-left: 0.5em;
			padding-right: 0.5em;
			margin-right: 0.5em;
			margin-top: 0.3em;
			height: 1.5em;
			color: $Light;
			background-color: $mainColor;
			border-radius: 0.5em;
			border: 2px solid $Light;
			cursor: pointer;
			transition: all 0.5s ease;

			&:hover {
				color: $mainColor;
				border: 2px solid $mainColor;
				background-color: $Light;
				padding-left: 1em;
				padding-right: 1em;

				svg {
					fill: $mainColor;
				}
			}
		}
		button.icon {
			border: none;
			margin-left: 1.5em;
		}

		button.author-register,
		button.quote-register {
			@include font-size(14px, 22px);
			border: none;
			padding-right: 0;
		}

		.custom-select {
			// @include font-size(14px, 24px);
			color: $Light;
			margin: 0.5em 0;
			padding: 0 0.5em;
			height: 1.5em;
			background: $mainColor;
			outline: none;
			cursor: pointer;
			border: 2px solid $Light;
			border-radius: 0.5em;
			box-shadow: 3px 3px 6px $ExtraLight, -1px -1px 6px $ExtraLight;
		}
	}
}

// Small Mobile
@media only screen and (max-width: 320px) {
	.component_header-line {
		padding-top: 0;

		.line-items {
			button.author-register,
			button.quote-register {
				font-size: 12px;
				border: none;
			}
		}
	}
}

// Mobile Big
@media only screen and (min-width: 400px) {
	.component_header-line {
		padding-top: 0.5em;

		.line-items {
			.custom-select {
				@include font-size(16px, 20px);
			}
		}
	}
}

// Desktop Size
@media only screen and (min-width: $maxWidth) {
	.component_header-line {
		height: 6vh;
		padding-top: 0;
		padding-left: 1em;
		padding-right: 1em;

		.line-items {
			.custom-select {
				@include font-size(16px, 20px);
				margin: 0;
				// border: none;
				box-shadow: none;
			}
		}
	}
}
