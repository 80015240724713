* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	// background: #000000; /* fallback for old browsers */
	// background: -webkit-linear-gradient(
	// 	to right,
	// 	#434343,
	// 	#000000
	// ); /* Chrome 10-25, Safari 5.1-6 */
	// background: linear-gradient(
	// 	to right,
	// 	#434343,
	// 	#000000
	// ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-color: $mainColor;
	font-family: 'Roboto', sans-serif;
}
