.policy {
	background: $Light;
	color: $Dark;
	width: 100vw;
	overflow: hidden;

	header {
		@include font-size(20px, 28px);
		display: block;
		width: 100%;
		height: 5vh;
		color: $Light;
		background-color: $mainColor;
		text-align: center;
		line-height: 5vh;
		margin-bottom: 1em;
		z-index: 10;

		.icon {
			display: flex;
			height: 5vh;
			position: absolute;
			color: $Light;
			top: 0;
			right: 1em;
		}
	}

	#top {
		position: absolute;
		top: 0;
		height: 6vh;
		color: $Light;
	}

	main {
		display: block;
		width: 100%;
		height: 85vh;
		padding: 1vh 1em;
		color: $Dark;
		background-color: $Light;
		overflow: hidden;
		overflow: auto;

		h1 {
			color: $Dark;
			display: flex;
			justify-content: center;
			margin-bottom: 1em;
		}
		p {
			text-align: justify;
			margin-bottom: 4rem;
		}
	}
}
