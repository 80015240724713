// RESPONSIVE (VIEWPORT SIZES)
$minWidth: 350px !default;
$maxWidth: 1920px !default;

// Colors:
$mainColor: rgb(0, 122, 255); // Apple System Light Blue #007AFF
$mainColorhover: rgb(0, 100, 200); // #0064C8
$Light: rgb(247, 247, 247); // My White #F7F7F7
$backColor: rgb(230, 230, 230); // Background color #E6E6E6
$Dark: rgb(28, 28, 30); // My Black #1C1C1C
$MedLight: rgb(142, 142, 147); // My Gray: Silver #8E8E93
$Danger: rgb(255, 59, 48); // Apple System Light Red #FF3B30
$ExtraLight: rgba(1, 1, 1, 0.1);

// Social Media
$Amazon: #ff9900;
$Facebook: #3b5998;
$Github: #000;
$Instagram: #dd2a7b;
$Linkedin: #0077b5;
$Telegram: #0088cc;
$Twitter: #1da1f2;
$Whatsapp: #00bb2d;
$Print: 'red';
