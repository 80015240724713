.components_footer-container {
	@include font-size(16px, 28px);
	display: flex;
	justify-content: center;
	width: 100%;
	height: 5vh;
	color: $Light;
	background-color: $mainColor;
	z-index: 10;

	.components_footer {
		span.link {
			font-size: 0.8em;
			color: $Light;
			padding: 0 0.5em;
			line-height: 5vh;
			transition: all 0.5s ease;

			&:hover {
				color: $mainColor;
				background: $Light;
				border-radius: 0.5em;
			}
		}

		span.active {
			font-size: 0.9em;
			padding: 0 0.5em;
			line-height: 5vh;
			color: orange;
			border: 1px solid orange;
			border-radius: 1em;
			// cursor: none;
			pointer-events: none;
		}
	}
}
