.btn {
	@include font-size(16px, 22px);
	padding: 0.4em 1.5em;
	background: $mainColor;
	color: white;
	cursor: pointer;
	border: none;
	border-radius: 3px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
	transition: all 1s ease;

	&:hover,
	:focus {
		background-image: linear-gradient(45deg, $mainColor, $Dark);
		transform: scale(1.1);
	}
}

.btn-small {
	@include font-size(12px, 16px);
	padding: 0.3em 1.3em;
}

.btn-container {
	margin: 2rem;
	text-align: center;
}

.btn__social {
	@include font-size(20px, 26px);
	color: $Light;
	line-height: 1.5em;
	padding: 0.2em 0.8em;
	text-decoration: none;
	display: flex;
	justify-content: space-around;
	align-items: center;

	svg {
		@include size(20px, 26px, 1, 1);
		margin-right: 0.5em;
		path {
			fill: $Light;
		}
	}
}

.footer {
	@include font-size(16px, 28px);
	background: transparent;
	border: none;
	span.footer {
		font-size: 0.8em;
		color: $Light;
		padding: 0 0.5em;
		line-height: 5vh;
		background-color: $mainColor;
		transition: all 1s ease;

		&:hover {
			color: $mainColor;
			background: $Light;
			border-radius: 0.5em;
		}
	}
}

.btn__facebook {
	background-color: $Facebook;
}

.btn__whatsapp {
	background-color: $Whatsapp;
}

.btn__twitter {
	background-color: $Twitter;
}

.btn__instagram {
	background-color: $Instagram;
}

.btn__telegram {
	background-color: $Telegram;
}

@media screen and (max-width: 350px) {
	.btn {
		font-size: 14px;
		padding: 0.2em 0.6em;
	}
}
